import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AccountingServices = () => (
  <Layout>
    <SEO title="Accounting Services" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-blue-main">Accounting Services</h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              Take full advantage of our Revenue Accounting and Joint Interest
              Billing services. Cut down on administrative costs and delays. You
              need not maintain a fully staffed accounting department.
            </p>
            <p>
              With our considerable experience in the field, our team of
              professionals will always make your needs our priority. Expect
              top-notch service, well-organized accounting books, and excellent
              financial reports.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">Accounting Services We Offer:</h2>
          </div>
          <div className="col-lg-10 mx-auto">
            <ul style={{ columns: 2 }}>
              <li>Perform Account Reconciliation</li>
              <li>Option to Net Revenue Distribution against JIB Invoices</li>
              <li>JOINT INTEREST SERVICES</li>
              <li>Process Monthly COPAS Overhead</li>
              <li>Prepare Monthly/Royalty Distributions</li>
              <li>Prepare Monthly Invoices to Joint Interest Owners</li>
              <li>Record Monthly Revenue</li>
              <li>Load Production Volumes</li>
              <li>Prepare Monthly LOE & Capital Cost Accruals</li>
              <li>Setup & Maintain JIB Schedules</li>
              <li>Perform Production/Revenue Volume Variance Analysis</li>
              <li>Perform Account Reconciliation</li>
              <li>Setup AFE’s</li>
              <li>Monitor Accounts Receivable Ageing Reports</li>
              <li>Prepare Monthly Revenue Accruals</li>
              <li>Enter Budget & Actual Cost Comparisons</li>
              <li>Calculate & Distribute Net Profit Interest</li>
              <li>Revenue accounting services</li>
            </ul>
          </div>
          <div className="col-lg-12">
            <p>
              Let us know how we can help you via our email (
              <a
                href="mailto:info@pacificcoastls.com"
                className="text-blue-main mx-1"
              >
                info@pacificcoastls.com
              </a>
              ) or phone number (
              <a href="tel:720.234.1155" className="text-blue-main mx-1">
                720.234.1155
              </a>
              )
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AccountingServices
